/**
 * Generated class for the FacaPartePage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

 import { HttpClient, HttpErrorResponse } from "@angular/common/http";
 import { Component, AfterViewInit } from "@angular/core";
 import { NgForm } from "@angular/forms";
 import { Plugins } from "@capacitor/core";
 import {
   NavController,
   ToastController,
   AlertController,
   Platform,
 } from "@ionic/angular";
 import { finalize } from "rxjs/operators";
 import { AplicativosService } from "src/app/services/aplicativos.service";
 import { AuthService } from "src/app/services/auth.service";
 import { GlobalService } from "src/app/services/global.service";
 import { HelpersService } from "src/app/services/helpers.service";
 import { LoadingService } from "src/app/services/loading.service";
 import { PageParamsService } from "src/app/services/page-params.service";
 import { CupomService } from "src/app/services/cupom.service";
 import { EsqueciSenhaPage } from "../esqueci-senha/esqueci-senha";
 import { LoginPage } from "../login/login";
 
@Component({
  selector: 'app-valida-p-saude',
  templateUrl: './valida-p-saude.page.html',
  styleUrls: ['./valida-p-saude.page.scss'],
})
export class ValidaPSaudePage implements  AfterViewInit{
  aceito = false;
  
  user = {
    registro: "",
  };

  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public toastCtrl: ToastController,
    public helpers: HelpersService,
    public _loading: LoadingService,
    public global: GlobalService,
    public http: HttpClient,
    public auth: AuthService,
    public alert: AlertController,
    public plt: Platform,
    public aplicativosService: AplicativosService,
    public cupomCtrl: CupomService
  ) { }

  ngAfterViewInit() { }

  async onSubmit(form: NgForm) {
    this.helpers.formMarkAllTouched(form);
    
    if (!this.aceito) {
      (
        await this.alert.create({
          header: "Termos de Uso",
          message:
            "Você deve aceitar os termos de uso e condição para poder se cadastrar",
          buttons: ['OK']
        })
      ).present();
      return;
    }

    if (!form.valid) {
      
      (
        await this.toastCtrl.create({
          message: "Informe CRM ou um CRN",
          duration: 4000,
        })
      ).present();
      return;
    }

    await this._loading.present();
    
    this.cupomCtrl.submitCodigo(this.user.registro,this.auth.user.user.id)
    .then( async(response) =>{
        console.log("resposta");
        console.log(response);

      (
        await this.alert.create({
          header: "Validação CRM/CRN",
          message:
            "Módulo liberado",
          buttons: ['OK']
        })
      ).present();

      this.navCtrl.navigateForward("/aplicativos");
    })
    .finally(async () => {
      this._loading.dismiss();
    });



  }

  termosDeUso() {

    Plugins.Browser.open({
      url: this.cupomCtrl.termo,
      windowName: "system",
    });
  }
}
