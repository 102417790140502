import { Component, ViewChild } from "@angular/core";

import {
  AlertController,
  IonRouterOutlet,
  MenuController,
  NavController,
  Platform,
} from "@ionic/angular";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { AuthService } from "./services/auth.service";
import { ApiService } from "./services/api.service";
import { GlobalService } from "./services/global.service";
import { AplicativosService } from "./services/aplicativos.service";
import { CategoriasService } from "./services/categorias.service";
import { PdfsService } from "./services/pdfs.service";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { PlanosService } from "./services/planos.service";
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  @ViewChild(IonRouterOutlet) routerOutlet: IonRouterOutlet;

  constructor(
    private plt: Platform,
    private statusBar: StatusBar,
    private oneSignal: OneSignal,
    public alertCtrl: AlertController,
    public auth: AuthService,
    public api: ApiService,
    public global: GlobalService,
    public navCtrl: NavController,
    public aplicativos: AplicativosService,
    public _categorias: CategoriasService,
    public _pdfs: PdfsService,
    public menuCtrl: MenuController,
    public splashScreen: SplashScreen,
    public planosService: PlanosService
  ) {
    this.boot();
    // boot services
    this.planosService.boot();
  }
  async boot() {
    this.global.loadingApp = false;
    this.auth.setToken(window.localStorage.getItem("token"));
    if (this.auth.isLoggedIn()) {
      if (await this.auth.isPremiumAsync()) {
          this.navCtrl.navigateRoot("/aplicativos");
      } else {
        this.aplicativos.pageToBePremium();
      }
    } else {
      if (this.aplicativos.appType === "ipe-saude") {
        this.aplicativos.askIpeSaude();
      } else if (this.aplicativos.appType === "my-fast-blend") {
        this.aplicativos.askMyFastBlendCode();
      }
      else {
        this.navCtrl.navigateRoot("/login");
      }
    }

    this.initializeApp();
  }
  closeMenu() {
    this.menuCtrl.close("first");
  }
  initializeApp() {
    this.plt.ready().then(async () => {
      // Okay, so the plt is ready and our plugins are available.
      // Here you can do any higher level native things you might need.

      if (this.plt.is("cordova")) {
        this.oneSignal.startInit(
          "02e3372a-f6fd-439e-bfa3-3b17cfc28a51",
          "621259710348"
        );

        this.oneSignal.inFocusDisplaying(
          this.oneSignal.OSInFocusDisplayOption.Notification
        );

        this.oneSignal.handleNotificationOpened().subscribe(async (r) => {
          const data = r.notification.payload.additionalData;
          // if (data.table == 'codes') {
          //   this.openTabPage(6);
          // }
          // do something when a notification is opened
          // console.log(r);
          (
            await this.alertCtrl.create({
              header: r.notification.payload.title,
              message: r.notification.payload.body,
            })
          ).present();
        });

        this.oneSignal.getIds().then((r) => {
          this.auth.player_id = r.userId;
          this.api.saveOneSignal();
        });

        this.oneSignal.endInit();
      }

      this.statusBar.backgroundColorByHexString("#000000");
      if (this.plt.is("android")) {
        this.statusBar.styleLightContent();
      }
      if (this.plt.is("ios")) {
        this.statusBar.styleDefault();
      }
      this.splashScreen.show();
    });
  }
}
