export class Cupom {
    readonly aplicar_no_total: boolean;
    readonly ativo: boolean;
    codigo: string;
    readonly condicao_cliente: string;
    readonly condicao_produto: string;
    readonly cumulativo: boolean;
    descricao: string;
    readonly quantidade: number;
    readonly quantidade_por_cliente: number;
    readonly tipo: string;
    valor: string;

    constructor(){
        this.aplicar_no_total = false;
        this.ativo = true;
        this.condicao_cliente = 'todos_clientes';
        this.condicao_produto = 'todos_produtos';
        this.cumulativo = false;
        this.quantidade = 10000;
        this.quantidade_por_cliente = 10000;
        this.tipo = "porcentagem";
    }
}