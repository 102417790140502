import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

/*
  Generated class for the GlobalService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: "root",
})
export class GlobalService {
  loadingApp = true;
  
  apiUrl = 'https://gestao.myfastblend.com.br/api';
  
  // apiUrl = 'http://localhost:8282/api';

  informacoes: any = {};
  conheca: any = {};

  constructor(public http: HttpClient) {
    this.informacoes = JSON.parse(window.localStorage.getItem("informacoes"));
    this.informacoes = this.informacoes ? this.informacoes : {};
    this.http.get(this.apiUrl + "/informacoes").subscribe((r) => {
      this.informacoes = r;
      window.localStorage.setItem(
        "informacoes",
        JSON.stringify(this.informacoes)
      );
    });

    this.conheca = JSON.parse(window.localStorage.getItem("conheca"));
    this.conheca = this.conheca ? this.conheca : {};
    this.http.get(this.apiUrl + "/conheca").subscribe((r) => {
      this.conheca = r;
      window.localStorage.setItem("conheca", JSON.stringify(this.conheca));
    });
  }
}
