/**
 * Generated class for the FacaPartePage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Component, AfterViewInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Plugins } from "@capacitor/core";
import {
  NavController,
  ToastController,
  AlertController,
  Platform,
} from "@ionic/angular";
import { finalize } from "rxjs/operators";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { AuthService } from "src/app/services/auth.service";
import { GlobalService } from "src/app/services/global.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";
import { EsqueciSenhaPage } from "../esqueci-senha/esqueci-senha";
import { LoginPage } from "../login/login";

@Component({
  selector: "page-faca-parte",
  templateUrl: "faca-parte.html",
  styleUrls: ["faca-parte.scss"],
})
export class FacaPartePage implements AfterViewInit {
  // @ViewChild('video') video: ElementRef;
  user = {
    first_name: "",
    telefone: "",
    email: "",
    password: "",
    confirmPassword: "",
    cpf: "",
  };
  showVideo = false;
  aceito = false;
  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public toastCtrl: ToastController,
    public helpers: HelpersService,
    public _loading: LoadingService,
    public global: GlobalService,
    public http: HttpClient,
    public auth: AuthService,
    public alert: AlertController,
    public plt: Platform,
    public aplicativosService: AplicativosService
  ) { }

  ngAfterViewInit() { }
  ionViewWillEnter() {
    setTimeout(() => {
      if (this.auth.isLoggedIn()) {
        this.navCtrl.navigateRoot("/aplicativos");
      }
    }, 300);
  }
  termosDeUso() {
    if (!this.global.informacoes.termos_de_uso) {
      return;
    }
    Plugins.Browser.open({
      url: this.global.informacoes.termos_de_uso,
      windowName: "system",
    });
  }
  async onSubmit(form: NgForm) {

    this.helpers.formMarkAllTouched(form);
    if (!this.aceito) {
      (
        await this.alert.create({
          header: "Termos de Uso",
          message:
            "Você deve aceitar os termos de uso e condição para poder se cadastrar",
          buttons: ['OK']
        })
      ).present();
      return;
    }

    if (!this.helpers.isEmail(this.user.email)) {
      form.controls.email.setErrors({ invalid: true });
      (
        await this.toastCtrl.create({
          message: "Informe um e-mail válido",
          duration: 4000,
        })
      ).present();
      return;
    }
    if (this.user.password.length < 6) {
      (
        await this.toastCtrl.create({
          message: "Informe uma senha com pelo menos 6 caracteres",
          duration: 4000,
        })
      ).present();
      return;
    }
    if (this.user.password != this.user.confirmPassword) {
      (
        await this.toastCtrl.create({
          message: "As senhas não conferem",
          duration: 4000,
        })
      ).present();
      return;
    }

    if (!form.valid) {
      (
        await this.toastCtrl.create({
          message:
            "Verifique se todos os campos estão preenchidos corretamente",
          duration: 4000,
        })
      ).present();
      return;
    }

    await this._loading.present();
    this.http
      .post(this.global.apiUrl + "/register", {
        ...this.user,
        ipe_saude: this.aplicativosService.userIsIpeSaude ? 1 : 0
      })
      .pipe(
        finalize(() => {
          this._loading.dismiss();
        })
      )
      .subscribe(
        async (r: any) => {
          (
            await this.toastCtrl.create({
              message: this.user.first_name + ", sua conta foi criada!",
              duration: 4000,
            })
          ).present();
          // Object.assign(this.loginPage.credentials, this.user);
          // setTimeout(() => this.loginPage.onSubmit(), 300);
          this.navCtrl.navigateForward("/login");
          this.pageParams.set("login", { user: this.user });
        },
        async (e: HttpErrorResponse) => {
          this._loading.dismiss();
          if (e.status == 400) {
            (
              await this.toastCtrl.create({
                message: e.error.message,
                duration: 4000,
              })
            ).present();
          } else {
            (
              await this.toastCtrl.create({
                message: "Por favor, tente novamente",
                duration: 4000,
              })
            ).present();
          }
        }
      );
  }

  esqueciSenhaPage() {
    this.navCtrl
      .pop()
      .then(() => this.navCtrl.navigateForward("/esqueci-senha"));
  }

  backspaceTelefone() {
    if (this.user.telefone.length <= 3) {
      this.user.telefone = "";
    }
  }
}
