import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import {
  AlertController,
  NavController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Subscription } from "rxjs";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { AuthService } from "src/app/services/auth.service";
import { CleanupService } from "src/app/services/cleanup.service";
import { GlobalService } from "src/app/services/global.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";

/**
 * Generated class for the LoginPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: "page-login",
  templateUrl: "login.html",
})
export class LoginPage implements AfterViewInit, OnDestroy {
  user: any = {
    email: "",
    password: "",
  };
  subscription: Subscription;
  constructor(
    public global: GlobalService,
    public helpers: HelpersService,
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public auth: AuthService,
    public loading: LoadingService,
    public toastCtrl: ToastController,
    public splashScreen: SplashScreen,
    public http: HttpClient,
    public aplicativos: AplicativosService,
    public statusBar: StatusBar,
    public cleanup: CleanupService,
    public route: ActivatedRoute,
    public alertCtrl: AlertController,
    public plt: Platform,
  ) {
    this.subscription = this.route.queryParams.subscribe((params) => {
      if (params.logout) {
        this.pageParams.clear("login");
        this.auth.logout();
      }
    });
  }
  ionViewWillEnter() {
    setTimeout(() => {
      
      if (this.auth.isLoggedIn()) {
        this.navCtrl.navigateRoot("/aplicativos");
      } else if (this.pageParams.get("login", "user")) {
        this.user = this.pageParams.get("login", "user");
        this.onSubmit();
      }
    }, 600);
    this.aplicativos.clearTheme();
    this.aplicativos.clearItem();
    this.cleanup.execute();
    this.statusBar.backgroundColorByHexString("#000000");
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.aplicativos.clearItem();
  }

  async onSubmit(form?) {
    if (form) {
      this.helpers.formMarkAllTouched(form);
    }
    if (!(this.user.email || this.user.password)) {
      (
        await this.toastCtrl.create({
          message: "Por favor, informe seu e-mail e sua senha",
          duration: 3000,
        })
      ).present();
      return;
    }

    await this.loading.present();
    this.auth.login(this.user).subscribe(
      async (res: any) => {
        this.auth.setToken(res.token);

        if (!this.pageParams.get("login", "user")) {
          (
            await this.toastCtrl.create({
              message:
                "Olá, " +
                this.auth.user.user.first_name +
                ", seja bem vindo novamente!",
              duration: 3000,
            })
          ).present();
        } else {
          setTimeout(async () => {
            (
              await this.toastCtrl.create({
                message:
                  "Olá, " +
                  this.auth.user.user.first_name +
                  ", seja bem vindo!",
                duration: 3000,
              })
            ).present();
          }, 3000);
        }

        if (await this.auth.isPremiumAsync()) {
          this.navCtrl.navigateRoot("/aplicativos");
        } else {
          this.aplicativos.pageToBePremium();
        }
        this.aplicativos.loginIpeSaude();
        this.loading.dismiss();
      },
      async (e) => {
        console.log(e);
        if (e.error?.error === "confirm_email") {
          this.confirmEmail(e.error?.message);
        } else {
          (
            await this.toastCtrl.create({
              message: "Credenciais inválidas, por favor tente novamente",
              duration: 3000,
            })
          ).present();
        }
        this.loading.dismiss();
      }
    );
  }

  tabsPage() {
    this.navCtrl.navigateRoot("/easymind-tabs");
  }

  async confirmEmail(message?: string) {
    const alert = await this.alertCtrl.create({
      header: "Confirmar e-mail",
      message:
        message ||
        "Verifique sua caixa de entrada ou caixa de spam do seu e-mail e coloque o código recebido aqui",
      inputs: [
        {
          name: "code",
          placeholder: "Código",
          label: "Código",
        },
      ],
      buttons: [
        {
          role: "cancel",
          text: "Cancelar",
        },
        {
          text: "Confirmar",
          handler: (input) => {
            console.log(input, "handler ocnfirmar");
            this.postConfirmEmail(input.code);
          },
        },
      ],
    });
    alert.present();
  }

  async postConfirmEmail(code) {
    await this.loading.present();
    this.http
      .post(this.global.apiUrl + "/confirm-email", {
        email: this.user.email,
        code: code,
      })
      .subscribe(
        async (r) => {
          await this.loading.dismiss();
          this.onSubmit();
        },
        async (e) => {
          await this.loading.dismiss();
          this.confirmEmail();
          (
            await this.toastCtrl.create({
              message: "Código inválido, tente novamente",
            })
          ).present();
        }
      );
  }

  isDesktopVersion(): boolean {
    // return this.plt.is('desktop') || this.plt.is('mobileweb');
    return false;
  }
}
