import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import { AlertController, NavController } from "@ionic/angular";
import { CategoriasService } from "src/app/services/categorias.service";
import { GlobalService } from "src/app/services/global.service";
import { CupomService } from "src/app/services/cupom.service";
import { PageParamsService } from "src/app/services/page-params.service";
import { Cupom } from "src/app/models/cupom.model";
import { AuthService } from "src/app/services/auth.service";
import { LoadingService } from "src/app/services/loading.service";

@Component({
  selector: "app-categorias-conteudos-content",
  templateUrl: "./categorias-conteudos-content.component.html",
  styleUrls: ["./categorias-conteudos-content.component.scss"],
})
export class CategoriasConteudosContentComponent implements AfterViewInit {
  loading = false;
  @Output() onRequestEnd = new EventEmitter();
  constructor(
    public global: GlobalService,
    public _categorias: CategoriasService,
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public cupomCtrl: CupomService,
    public auth: AuthService,
    public loadingCtrl: LoadingService,
    public alertCtrl: AlertController,
  ) { }

  ngAfterViewInit() {
    if (!this._categorias.list[0]?.id) {
      this._categorias
        .getAll({ completo: true })
        .finally(() => this.getConteudos());
    } else {
      this.getConteudos();
    }

    console.log("teste");
    console.log(this._categorias.current);
    
  }

  getConteudos() {
    if (!this._categorias.current?.id) {
      this._categorias.current = this._categorias.list[0];
    }
    this.loading = true;
    return this._categorias
      .getTopicos(this._categorias.current.id)
      .then((r: any) => {
        this._categorias.current.topicos = r;
      })
      .finally(() => {
        this.loading = false;
        this.onRequestEnd.emit();
      });
  }

  certificadosPagarPage() {
    this.navCtrl.navigateForward("/certificados-pagar");
    this.pageParams.set("certificados-pagar", {
      categoria: Object.assign({}, this._categorias.current),
    });
  }

  async getCupomProfissional() {
    let cupom: Cupom = new Cupom();
    cupom.valor = "30.00";
    cupom.descricao = "Desc. prof. Saúde: " + this.auth.user.user.first_name;
    cupom.codigo = this.cupomCtrl.getCodigoCupom(this.auth.user.user.first_name, '30');
    await this.loadingCtrl.present();

    this.cupomCtrl.postCupom(cupom)
      .then(async (resp: any) => {
        if (resp.codigo) {
          let alerta = await this.getAlerta("Parabéns você acaba de ganhar um cupom com 30% de desconto para usar em suas compras: " + resp.codigo);
          alerta.present();
        }

        if (resp.error_message) {
          let alerta = await this.getAlerta("Utilize o código: " + cupom.codigo);
          alerta.present();  
        }
      }).catch(async (e) => {
        let alerta = await this.getAlerta("Utilize o código: " + cupom.codigo);
        alerta.present();
      }
      )
      .finally(() => {
        this.loadingCtrl.dismiss();
      }
      );

  }

  async getCupomPaciente() {
    let cupom: Cupom = new Cupom();
    cupom.valor = "15.00";
    cupom.descricao = "Desc. prof. Saúde (paciente): " + this.auth.user.user.first_name;
    cupom.codigo = this.cupomCtrl.getCodigoCupom(this.auth.user.user.first_name, '15');

    await this.loadingCtrl.present();

    this.cupomCtrl.postCupom(cupom)
      .then(async (resp: any) => {
        if (resp.codigo) {
          let alerta = await this.getAlerta("Parabéns você acaba de ganhar um cupom com 15% de desconto para distribuir para os seus pacientes: " + resp.codigo);
          alerta.present();
        }

        if (resp.error_message) {
          let alerta = await this.getAlerta("Utilize o código: " + cupom.codigo);
          alerta.present();  
        }
      }).catch(async (e) => {
        let alerta = await this.getAlerta("Utilize o código: " + cupom.codigo);
        alerta.present();
      }
      )
      .finally(() => {
        this.loadingCtrl.dismiss();
      }
      );
  }

  async getAlerta(mensagem: string) {
    return await this.alertCtrl.create({
      header: "Cumpom promocional",
      message:
        mensagem,
      buttons: [
        {
          role: "cancel",
          text: "Cancelar",
        },
        {
          text: "Confirmar",
        },
      ],
    });
  }

  async descricaoProfissional() {
      (
        await this.alertCtrl.create({
          header: "Profissional da Saúde",
          message: this.cupomCtrl.getDescricaoProfissional().replace(/(?:\r\n|\r|\n)/g, "<br>"),
          cssClass: "alert-lg",
          buttons: [
            {
              role: "cancel",
              text: "Ok",
            },
          ],
        })
      ).present();
  }

  async descricaoPaciente() {
    (
      await this.alertCtrl.create({
        header: "Pacientes",
        message: this.cupomCtrl.getDescricaoPaciente().replace(/(?:\r\n|\r|\n)/g, "<br>"),
        cssClass: "alert-lg",
        buttons: [
          {
            role: "cancel",
            text: "Ok",
          },
        ],
      })
    ).present();
  }
}
