import { Component, OnInit } from '@angular/core';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { HelpersService } from 'src/app/services/helpers.service';

@Component({
  selector: 'app-my-fast-blend-modal-code',
  templateUrl: './my-fast-blend-modal-code.page.html',
  styleUrls: ['./my-fast-blend-modal-code.page.scss'],
})
export class MyFastBlendModalCodePage implements OnInit {

  obj = {
    code: "",
  };

  constructor(
    public navCtrl: NavController,
    public modalController: ModalController,
    public helpers: HelpersService,
    public statusBar: StatusBar,
    public toastCtrl: ToastController,
  ) { 
    this.statusBar.backgroundColorByHexString("#000000");
    if (this.helpers.isAppleReview()) {
      this.navCtrl.navigateForward("/aplicativos");
    }
  }

  ngOnInit() {
  }
  
  async onSubmit() {
    if (this.obj.code.length !== 0) {
      this.modalController.dismiss({
        'data': this.obj.code
      });
    } else {
      (await this.toastCtrl.create({
        message: "Digite um código válido",
        duration: 4000,
      })
      ).present();
    }
  }

  async dismissModal(){
    console.log("entrou para fechar");
    this.modalController.dismiss();
  }
}
