import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { default as jwt_decode } from "jwt-decode";
import * as moment from "moment";
import { GlobalService } from "./global.service";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  knownRoles = new Array<string>();
  knownPermissions = new Array<string>();
  player_id = "";
  token: string;
  user: any;

  previousUrl = "";

  constructor(
    public http: HttpClient,
    public global: GlobalService, // public helpers: HelpersService
    public alertCtrl: AlertController,
  ) {
    this.setToken(window.localStorage.getItem("token"));
  }

  isPremium() {
    // console.log(this.user);
    if (!this.isLoggedIn()) {
      return false;
    }
    if (!this.user.user.pessoa) {
      return false;
    }
    if (!this.user.user.pessoa.code) {
      return false;
    }
    if (
      !moment(this.user.user.pessoa.code.expire_at, "DD/MM/YYYY").isAfter(
        moment()
      )
    ) {
      return false;
    }
    return true;
  }

  async isPremiumAsync() {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.global.apiUrl + "/users/" + this.user?.user?.id + "/premium", {
          headers: this.getAuthorizationHeader(),
        })
        .subscribe(
          (r: any) => {
            if (r.premium) {
              resolve(true);
            } else {
              resolve(false);
            }
          },
          (e) => resolve(false)
        );
    });
  }

  login(user) {
    let body = user;
    return this.http.post(this.global.apiUrl + "/login", body);
  }
  
  logout() {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("codigo-myfastblend");
    this.token = undefined;
    this.user = undefined;
    this.knownPermissions = new Array<string>();
    this.knownRoles = new Array<string>();
    if (this.player_id.length) {
      this.http
        .delete(this.global.apiUrl + "/users/onesignal/" + this.player_id)
        .subscribe();
    }
  }

  setToken(t: string) {
    if (!t) {
      return;
    }
    const tks: Array<any> = t.split(".");
    if (tks.length !== 3) {
      console.error("The specified token does not contain the 3 parts.");
      window.localStorage.removeItem("token");
      return;
    }

    this.token = t;
    this.user = jwt_decode(this.token);
    if (Array.isArray(this.user.user.permissions)) {
      this.user.user.permissions = {};
    }
    window.localStorage.setItem("token", this.token);
  }

  /**
   * Verificar se determinado usuário está no role ou não, caso não informado um user será utilizado o que atualmente está logado
   * @param role slug do role
   * @param user (opcional) Objeto de usuário contendo os seus roles
   */
  inRole(role: string, user?: { roles;[x: string]: any }): boolean {
    if (!this.user) {
      return false;
    }
    role = role.trim();
    let currentUser;
    let addToKnownRoles = false;
    if (user) {
      currentUser = user;
    } else {
      currentUser = this.user.user;
      addToKnownRoles = true;
      if (this.knownRoles.indexOf(role) !== -1) {
        return true;
      }
    }
    if (!currentUser.roles) {
      console.error("No roles found in the current user");
      return false;
    }

    // !! faz com que o valor venha em boolean
    return !!currentUser.roles.find((r: any) => {
      if (r.slug === role) {
        if (addToKnownRoles) {
          this.knownRoles.push(role);
        }
        return true;
      }
    });
  }

  inAnyRole(roles: Array<string>): boolean {
    if (!this.user) {
      return false;
    }
    for (let i = 0; i < roles.length; i++) {
      if (this.inRole(roles[i])) {
        return true;
      }
    }
  }

  hasAccess(check: Array<string>) {
    if (!this.user) {
      return false;
    }
    if (!this.user.user.roles) {
      console.error("No roles found in the current user");
      return false;
    }
    if (!this.user.user.permissions) {
      console.error("No permissions found in the current user");
      return false;
    }
    const checkCount = check.length;
    let hasAccessCount = 0;
    for (let i = 0; i < check.length; i++) {
      if (this.knownPermissions.indexOf(check[i]) !== -1) {
        hasAccessCount++;
      }
    }

    // check the permissions on the user
    check.forEach((c) => {
      if (hasAccessCount >= checkCount) {
        return;
      }
      if (
        this.user.user.permissions[c] !== undefined &&
        (this.user.user.permissions[c] === true ||
          this.user.user.permissions[c] === "true")
      ) {
        this.knownPermissions.push(c);
        hasAccessCount++;
      }
    });
    if (hasAccessCount >= checkCount) {
      return true;
    }
    // check the permissions on the roles that the user has
    this.user.user.roles.forEach((r: any) => {
      if (hasAccessCount >= checkCount) {
        return;
      }
      check.forEach((c) => {
        if (hasAccessCount >= checkCount) {
          return;
        }
        if (
          r.permissions[c] !== undefined &&
          (r.permissions[c] === true || r.permissions[c] === "true")
        ) {
          this.knownPermissions.push(c);
          hasAccessCount++;
        }
      });
    });
    return hasAccessCount >= checkCount;
  }
  hasAnyAccess(check: Array<string>) {
    let hasAnyAccess = false;
    check.forEach((c) => {
      if (hasAnyAccess) {
        return true;
      }
      if (this.hasAccess([c])) {
        hasAnyAccess = true;
      }
    });
    return hasAnyAccess;
  }

  getAuthorizationHeader() {
    return new HttpHeaders().append("Authorization", "Bearer " + this.token);
  }

  isLoggedIn() {
    const now = new Date();

    if (!this.token || this.user?.user?.exp < now.getTime() / 1000) {
      // this.logout();
      return false;
    }
    return true;
  }
}
