import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { Cupom } from '../models/cupom.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CupomService {

  url: string = 'http://api.awsli.com.br/v1/cupom/';
  termo: string = "https://phpstack-224727-2152875.cloudwaysapps.com/uploads/termos/termo_profissionais_saude.pdf";

  constructor(
    public api: ApiService,
    public alertCtrl: AlertController,
    public navCtrl: NavController,
  ) { }

  public postCupom(cupom: Cupom) {
    return this.api.post("/cupommfb",cupom).toPromise();
  }

  public getCodigoCupom(descricao: string, percentual: string): string {
    let codigo = 'mfb';
    codigo += this.getFirstLetters(descricao);
    codigo += this.getDate();
    codigo += percentual + "OFF";

    return codigo.toUpperCase();
  }

  private getFirstLetters(str) {
    const firstLetters = str
      .split(' ')
      .map(word => word[0])
      .join('');

    return firstLetters;
  }

  private getDate() {
    var d = new Date();
    var mes = String(d.getMonth() + 1).padStart(2, '0');
    var dia = String(d.getDate()).padStart(2, '0');

    return mes + dia;
  }

  async navBackApps(){
    console.log("navBackApps");
    
    this.navCtrl.navigateRoot('/aplicativos');
    
  }

  async askProfissionalSaude(){
    console.log("askProfissionalSaude");
    
    this.navCtrl.navigateForward('/valida-p-saude');
    
  }

  // async submitCodigo(codigo: string) {
  //   return new Promise((resolve) => {
  //     window.localStorage.setItem("registro", codigo);

  //     resolve(true);
  //   });
  // }

  // public hasRegistro(): boolean {
  //   let registro = window.localStorage.getItem("registro");

  //   return registro !== null;
  // }
  
  async submitCodigo(codigo: string, id:number ) {
    let body = {
      id: id,
      registro: codigo
    };

    return this.api.post("/reg-profissional",body).toPromise();
  }

  async hasRegistro(id: number) {
    let resposta = "" ;
    
    await  this.api.get("/reg-profissional/" + id)
    .toPromise()
    .then((response:any) =>{
      if(response.registro){
        resposta = response.registro;
      }
    });

    return resposta !== "";
  }

  getDescricaoProfissional() {
    let texto = "Como funciona?<br>Este cupom é para você utilizar exclusivamente para aquisição, em nossa loja virtual, de produtos para o seu consumo. Com ele você terá um desconto de até 30% sobre o valor dos produtos (o desconto não se aplica sobre o valor de frete).<br>Fazemos isso para que você possa conhecer e utilizar os nossos produtos, podendo indicar com ainda mais certeza para seus pacientes pois conhecerá na prática todos os benefícios do My Fast Blend. "
    return texto;
  }

  getDescricaoPaciente() {
    let texto = "Como funciona?<br>Este cupom é para você disponibilizar para seus pacientes adquirirem, em nossa loja virtual, produtos com até 15% de desconto sobre o valor dos produtos (o desconto não se aplica sobre o valor de frete).<br>Fazemos isso para que você possa indicar os produtos, ajudando os seus pacientes a melhorarem a qualidade de vida e auxilia-los a atingirem os seus objetivos, utilizando produtos realmente benéficos.";
    return texto
  }

}
